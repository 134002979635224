@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

code {
  font-family: "Inter", sans-serif !important;
}

#__next {
  text-transform: initial;
  overflow-y: auto !important;
}

#__next-build-watcher {
  z-index: 0 !important;
}

@layer base {
  html {
    font-family: "Inter", sans-serif !important;
  }

  ul,
  ol {
    list-style: revert;
  }

  span > ol {
    padding: 0 16px;
  }

  ol > li::marker {
    font-weight: bold;
  }

  ul {
    padding-left: 20px;
    padding-bottom: 20px;
    font-size: 15px;
  }
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f7f7f7;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

::-webkit-scrollbar-thumb:hover {
  background: #abadab;
}

/* .listItemMenu {
  span &:hover {
    span {
      color: #fff;
      background-color: #f4dbff46;
    }
  }
} */

.textGradient {
  background: linear-gradient(180deg, #fedf3f 0%, #f7871d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 16px !important;
}

.bgLabelVip {
  background-image: url("../public/assets/images/labelVipBackground.png");
}

.bgIntroduceBox {
  background-image: url("../public/assets/images/IntroduceBackground.webp");
}

/* .rowTable {
  position: relative;
  .loadingTable {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffb0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
} */

.itemMessage {
  display: flex;
  align-items: center;
  padding: 4px 16px 4px 8px;
  border-radius: 4px;
  position: relative;
}

.itemMessageError {
  color: #ff2c00;
  background: #fff0ed;
}

.itemMessageSuccess {
  color: #3f4445;
  background: #e9fcee;
}

.iconBrm {
  margin-right: 4px;
}

.boxShadow {
  border: 1px solid #d9dbdc;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.textEmail {
  color: #18a0fb;
}

.box_cover {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  padding: 24px 16px;
}

.arena-box_cover {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;

  @media (min-witdh: 368px) {
    padding: 0;
  }

  @media (min-witdh: 768px) {
    padding: 10px 16px;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.customPlaceholder::placeholder {
  color: #a9a9a9;
}

button {
  cursor: pointer !important;
}

.btn-menu {
  display: none;
}

.content-box:hover > .btn-menu,
.btn-menu:hover {
  display: inline-block;
  max-width: max-content !important;
}

.dot {
  height: 40px;
  width: 40px;
  background-color: #9e9e9e;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.bigDot {
  height: 64px;
  width: 64px;
  background-color: white;
  border-radius: 50%;
  border-width: 1px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.borderSelection {
  padding: 4px;
  height: 42px;
  min-width: 38px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px;
}

.MuiOutlinedInput-root,
.MuiInputBase-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl,
.MuiInputBase-sizeSmall,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.MuiButton-text,
.MuiButton-textPrimary,
.MuiTypography-root,
.MuiButton-root,
.MuiTab-root,
.MuiFormControl-root,
.MuiInputLabel-root,
.MuiMenuItem-root,
.MuiList-root {
  font-family: "Inter", sans-serif !important;
}

.Crop-Controls {
  margin-bottom: 10px !important;
}

.Crop-Controls > * {
  margin-bottom: 3px !important;
}

.ReactCrop {
  position: relative !important;
  display: inline-block !important;
  cursor: crosshair !important;
  cursor: crosshair !important;
  overflow: hidden !important;
  max-width: 100% !important;
}

.ReactCrop__child-wrapper {
  max-height: inherit !important;
}

.ReactCrop *,
.ReactCrop *::before,
.ReactCrop *::after {
  box-sizing: border-box !important;
}

.ReactCrop__crop-selection {
  position: absolute !important;
  transform: translate3d(0, 0, 0) !important;
  cursor: move !important;
  box-shadow: 0 0 0 9999em rgb(0 0 0 / 50%) !important;
  touch-action: none !important;
  border: 1px dashed #fff !important;
}

.ReactCrop__drag-handle::after {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  outline: 1px solid rgba(0, 0, 0, 0);
}

.img-preview > img {
  width: 80% !important;
}

.ReactCrop__crop-selection {
  /* border-radius: 9999px !important;
  width: 200px !important;
  height: 200px !important; */
  display: none !important;
}

.ReactCrop__child-wrapper:hover {
  cursor: pointer !important;
}

#createNameRoom::placeholder,
#createPassword::placeholder {
  font-size: 16px !important;
}

body.plugin,
.fb-page {
  font-family: "Inter", sans-serif !important;
  border-radius: 12px !important;
}

.slick-dots div {
  opacity: 0.4 !important;
}

.MuiSelect-select,
.MuiOutlinedInput-root,
.MuiMenuItem-root {
  color: rgb(0, 0, 0) !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #38b000 !important;
}

.text-green-gradient {
  background: linear-gradient(180deg, #9ef01a 0%, #007200 100%);
  background-clip: text !important;
  color: transparent !important;
}

.bg-green-gradient {
  background: linear-gradient(180deg, #9ef01a 0%, #007200 100%);
}

.border-gradient-green {
  border: 1px solid transparent;
  background: linear-gradient(to right, #ffffff, #ffffff), linear-gradient(to bottom right, #9ef01a, #007200);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.border-gradient-red {
  border: 1px solid transparent;
  background: linear-gradient(to right, #ffffff, #ffffff), linear-gradient(to bottom right, #ff777d, #bc051e);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.gradient-border-1 {
  border: 1px solid transparent;
}

.jlptUp-Breadcrumb span:last-child {
  font-weight: 500;
  color: #d82332 !important;
}

textarea {
  resize: none !important;
}

.text-yellow-gradient {
  background: linear-gradient(180deg, #ffd600 0%, #fb6107 100%);
  background-clip: text;
  color: transparent;
}

.text-primary-gradient {
  background: linear-gradient(212.84deg, #ff777d 3.8%, #bc051e 89.89%);
  background-clip: text;
  color: transparent;
}

.bg-linear-yellow {
  background: linear-gradient(180deg, #fedf3f 0%, #f7871d 100%);
}

#createRoomBox fieldset {
  border-radius: 30px !important;
  outline: #38b000 !important;
}

#createRoomBox ~ label {
  font-size: 12px !important;
}

.border-gradient-yellow {
  border: 1px solid transparent;
  background: linear-gradient(to right, #ffffff, #ffffff), linear-gradient(to bottom right, #fedf3f, #f7871d);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.search-content {
  top: 56px !important;
}

.jlptupBreadcrumb span:last-child {
  font-weight: 600;
  color: #d34545 !important;
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .jlptupBreadcrumb span:last-child {
    font-weight: 600;
    color: #d34545 !important;
    margin-top: 4px;
    margin-left: 0px;
  }
}

table {
  border-collapse: collapse !important;
}

#contentPost table td,
#contentPost table th {
  border-collapse: separate !important;
  border: 1px solid #212121 !important;
  text-align: center;
}

#contentPost table th {
  font-weight: 500;
}

.quizCategories:last-child {
  margin-right: 20px !important;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}
